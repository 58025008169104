import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import './ThreeContainer'
import ThreeContainer from './ThreeContainer';

class App extends Component {
  render() {
    var link = "https://instagram.com/phillipxkang/"
    if (/iPhone|iPad/i.test(navigator.userAgent)) {
      link = "instagram://user?username=phillipxkang"
    }
    if (/Android/i.test(navigator.userAgent)) {
      link = "intent://instagram.com/phillipxkang/#Intent;package=com.instagram.android;scheme=https;S.browser_fallback_url=https://instagram.com/phillipxkang/;end"
    }
    return (
      <div className="App">

        <ThreeContainer />
        <div className="promo">
          <a href={link} target="_blank">@phillipXkAng</a>
        </div>
      </div>
    );
  }
}

export default App;
