var THREE = require('three');
var Reflector = require('three-reflector')(THREE);
const OrbitControls = require('three-orbit-controls')(THREE);

function GeneralLights(scene) {
	
	const light = new THREE.PointLight("#2222ff", 1);
    scene.add(light);
	
	this.update = function(time) {
		// light.intensity = (Math.sin(time)+1.5)/1.5;
	}
}

function SceneMirror(scene){
  
                var geometry = new THREE.PlaneBufferGeometry( 20, 20 );
                const mesh = new THREE.Mesh(geometry, new THREE.MeshLambertMaterial({color: 0x0000ff, transparent: true, opacity: 0.0}));

				var verticalMirror = new Reflector( geometry, {
                    clipBias: 0.003,
                    textureWidth: window.innerWidth * window.devicePixelRatio,
                    textureHeight: window.innerHeight * window.devicePixelRatio,
                    color: 0x777777,
                    recursion: 1
                } );
                // verticalMirror.rotation.y = 1.0;

				verticalMirror.position.y = 0;
				verticalMirror.position.z = -30;
                scene.add( verticalMirror );

                mesh.position.set(0,0,-30);


                var eGeometry = new THREE.EdgesGeometry( mesh.geometry );
                var eMaterial = new THREE.LineBasicMaterial( { color: 0xffffff, linewidth: 4 } );
                var edges = new THREE.LineSegments( eGeometry , eMaterial );
                mesh.add( edges );

                scene.add(mesh);


                this.update = function(time) {
                }
   
}

function SceneMirror2(scene){
  
    var geometry = new THREE.PlaneBufferGeometry( 20, 20 );
    const mesh = new THREE.Mesh(geometry, new THREE.MeshLambertMaterial({color: 0x0000ff, transparent: true, opacity: 0.0}));

    var verticalMirror = new Reflector( geometry, {
        clipBias: 0.003,
        textureWidth: window.innerWidth * window.devicePixelRatio,
        textureHeight: window.innerHeight * window.devicePixelRatio,
        color: 0x777777,
        recursion: 1
    } );
    // verticalMirror.rotation.y = 1.0;

    verticalMirror.rotation.y = 3.14;
    verticalMirror.position.y = 0;
    verticalMirror.position.z = -10;
    scene.add( verticalMirror );

    mesh.rotation.y = 3.14;
    mesh.position.set(0,0,-10);


    var eGeometry = new THREE.EdgesGeometry( mesh.geometry );
    var eMaterial = new THREE.LineBasicMaterial( { color: 0xffffff, linewidth: 4 } );
    var edges = new THREE.LineSegments( eGeometry , eMaterial );
    mesh.add( edges );

    scene.add(mesh);


    this.update = function(time) {
    }

}

function SceneSubject(scene) {
	
// 	const radius = 3;
	const mesh = new THREE.Mesh(new THREE.BoxBufferGeometry(5, 5, 5), new THREE.MeshLambertMaterial({color: 0x0000ff, transparent: true, opacity: 0.0}));

  mesh.position.set(0, 0, -20);


var eGeometry = new THREE.EdgesGeometry( mesh.geometry );
var eMaterial = new THREE.LineBasicMaterial( { color: 0xffffff, linewidth: 4 } );
var edges = new THREE.LineSegments( eGeometry , eMaterial );
mesh.add( edges );

	scene.add(mesh);

	
	this.update = function(time) {
		const scale = Math.sin(time)*3;
    // mesh.position.set(scale, 0, -20);

    mesh.rotation.set(time,time,.1);
	}
}

function SceneAntibody(scene) {
	
    // 	const radius = 3;
        const mesh = new THREE.Mesh(new THREE.TorusKnotBufferGeometry(.5,.4,32,5), new THREE.MeshLambertMaterial({color: 0xffa500, transparent: true, opacity: 0.5}));
    
      mesh.position.set(0, 0, -20);
    
    
    var eGeometry = new THREE.EdgesGeometry( mesh.geometry );
    var eMaterial = new THREE.LineBasicMaterial( { color: 0xffa500, linewidth: 1 } );
    var edges = new THREE.LineSegments( eGeometry , eMaterial );
    mesh.add( edges );
    
        scene.add(mesh);
    
        
        this.update = function(time) {
            const scale = Math.sin(time)*2;
        // mesh.position.set(scale, 0, -20);
    
        mesh.rotation.set(scale,scale,.1);
        mesh.scale.set(scale,scale,scale);
        }
    }


function SceneManager(canvas) {


  const clock = new THREE.Clock();
  
  const screenDimensions = {
      width: canvas.width,
      height: canvas.height
  }
  
  const scene = buildScene();
  const renderer = buildRender(screenDimensions);
  const camera = buildCamera(screenDimensions);
  const sceneSubjects = createSceneSubjects(scene);

  var cameraControls = new OrbitControls( camera, renderer.domElement );
                cameraControls.target.set( 0, 0, -20 );
				cameraControls.maxDistance = 50;
                cameraControls.minDistance = 15;
				cameraControls.update();


  function buildScene() {
      const scene = new THREE.Scene();
      scene.background = new THREE.Color("#000");

      return scene;
  }

  function buildRender({ width, height }) {
      const renderer = new THREE.WebGLRenderer({ canvas: canvas, antialias: true, alpha: true }); 
      const DPR = (window.devicePixelRatio) ? window.devicePixelRatio : 1;
      renderer.setPixelRatio(DPR);
      renderer.setSize(width, height);

      renderer.gammaInput = true;
      renderer.gammaOutput = true; 

      return renderer;
  }

  function buildCamera({ width, height }) {
      const aspectRatio = width / height;
      const fieldOfView = 60;
      const nearPlane = 1;
      const farPlane = 100; 
      const camera = new THREE.PerspectiveCamera(fieldOfView, aspectRatio, nearPlane, farPlane);
      camera.position.set(10,-10,10);
      return camera;
  }

  function createSceneSubjects(scene) {
      const sceneSubjects = [
          new GeneralLights(scene),
          new SceneSubject(scene),
          new SceneMirror(scene),
          new SceneMirror2(scene),
          new SceneAntibody(scene)
      ];

      return sceneSubjects;
  }

  this.update = function() {
      const elapsedTime = clock.getElapsedTime();

      for(let i=0; i<sceneSubjects.length; i++)
        sceneSubjects[i].update(elapsedTime);

      renderer.render(scene, camera);
  }

  this.onWindowResize = function() {
      const { width, height } = canvas;

      screenDimensions.width = width;
      screenDimensions.height = height;

      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      
      renderer.setSize(width, height);
  }
}



export {SceneManager as SceneManager};